<template>
    <div v-if="toasts.length > 0" id="toast-container">
        <template v-for="toast in toasts">
            <component-toast :id="toast.id"
                             :key="toast.id"
                             :message="toast.message"
                             :title="toast.title"
                             :type="toast.type"
                             class="mb-3"/>
        </template>
    </div>
</template>
<script>
import ComponentToast from './ComponentToast.vue';

export default {
    name: "ComponentToastContainer",
    components: {ComponentToast},
    computed: {
        toasts() {
            return this.$store.state.toast.list;
        }
    }
}
</script>
<style lang="scss" scoped>
#toast-container {
    position: absolute;
    padding-top: 60px;
    padding-left: 1em;
    width: 400px;
    height: 100%;
    top: 0;
    right: 0;
    z-index: 2000;
    background-color: transparent;
    pointer-events: none;
    overflow-x: hidden;
    -ms-overflow-x: hidden;

    .toast {
        pointer-events: all;
        background-color: #e9ecef;
        animation-name: slide-in;
        animation-duration: .6s;
        animation-delay: -.2s;
    }

    @-webkit-keyframes slide-in {
        from {
            margin-left: 400px;
        }
        to {
            margin-left: 0;
        }
    }

    @keyframes slide-in {
        from {
            margin-left: 400px;
        }
        to {
            margin-left: 0;
        }
    }
}
</style>